<script>
import SeriesSchedule from "../components/SeriesSchedule.vue";
import SeriesLocation from "../components/SeriesLocation.vue";
import SeriesDescription from "../components/SeriesDescription.vue";
import { GetChapterTimezoneSlugAndOrganizersById, GetEventSeriesForCreateById } from "../../operations.gql";
import { SeriesScheduleFromICalText, UserChip } from "../../common.js";
import { bottomOfTheHour } from "@/utils/formatting/dates.js";

export default {
  name: "SeriesCreate",
  components: { SeriesSchedule, SeriesLocation, SeriesDescription },
  data() {
    return {
      currentStep: this.$route.query.step,
      chapter: null,
      // seriesId will be undefined until first step is finished
      seriesId: this.$route.params.seriesId,
      series: null
    };
  },
  apollo: {
    chapter: {
      query: GetChapterTimezoneSlugAndOrganizersById,
      variables() {
        return {
          id: this.$route.params.chapterId
        };
      },
      update(data) {
        if (data.result) {
          const chapterData = data.result;
          const organizers = data.result.chapter_members.map(org => {
            return UserChip(org.user_view);
          });
          return {
            id: chapterData.id,
            timezone: chapterData.place ? chapterData.place.timezone : null,
            organizers,
            slug: chapterData.slug
          };
        }
        return null;
      }
    },
    // GetEventSeriesForCreateById is called when this.seriesId is truthy
    series: {
      query: GetEventSeriesForCreateById,
      variables() {
        return {
          id: this.seriesId,
          endsAfter: bottomOfTheHour(new Date()).toISOString()
        };
      },
      skip() {
        return !this.seriesId;
      },
      update(data) {
        // You should be able to use rrule.js (which is already in the project) to load at least the schedule part(s) from the iCal text
        // The alternative is a new Query on the backend that parses it and returns the fields you need how you need. That might be best so that it's the same library going to and from iCal and not two separate libraries in two languages doing it
        if (data.result) {
          const schedule = SeriesScheduleFromICalText(data.result.ical_text, data.result.duration);
          const seriesData = data.result;
          const organizers = seriesData.organizers.map(org => {
            return UserChip(org.user_view);
          });
          const formattedSeriesData = {
            ...seriesData,
            schedule,
            organizers
          };
          return formattedSeriesData;
        }
        return null;
      }
    }
  },
  watch: {
    "$route.query.step"(newStep) {
      this.currentStep = newStep;
      this.checkAndFetchSeriesData();
    }
  },
  methods: {
    checkAndFetchSeriesData() {
      if (this.seriesId && this.currentStep !== "schedule") {
        this.fetchSeriesData();
      }
    },
    fetchSeriesData() {
      this.$apollo.queries.series.refetch();
    },
    handleNextStep(payload) {
      const { step, seriesId } = payload;
      if (seriesId) {
        this.seriesId = seriesId;
      }
      this.$router.push({
        name: "SeriesCreate",
        params: {
          chapterId: this.$route.params.chapterId,
          seriesId: this.seriesId
        },
        query: {
          step: step
        }
      });
      this.currentStep = step;
    }
  }
};
</script>

<template>
  <div>
    <SeriesSchedule v-if="currentStep === 'schedule' && chapter" :chapter="chapter" @next-step="handleNextStep" />
    <SeriesSchedule v-if="currentStep === 'schedule-edit' && chapter && series" :chapter="chapter" :series="series" @next-step="handleNextStep" />
    <SeriesLocation v-if="currentStep === 'location' && chapter && series" :series="series" :chapter="chapter" @next-step="handleNextStep" />
    <SeriesDescription v-if="currentStep === 'description' && chapter && series" :series="series" :chapter="chapter" @next-step="handleNextStep" />
  </div>
</template>
